@charset "utf-8";

@import "skins/blue"; // skin
@import "default"; // main partials

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}